import http from '@/utils/http'

// 智能预警记录分页查询
export const getQualityWarnPage = p => http.post('/unibu/warn/page', p)

// 智能预警记录统计
export const getQualityWarnSum = p => http.post('/unibu/warn/sum', p)

// 处置方式数据字典
export const gainWarnHandle = p => http.get('/unibu/dd/base_warn_handle', p)

// 整改方式数据字典
export const gainWarnRectify = p => http.get('/unibu/dd/base_warn_rectify', p)

// 智能预警记录整改
export const dealWarn = p => http.post('/unibu/warn/deal', p)
